// File: src/Router.js
import { createRouter, createWebHistory } from 'vue-router';
import MoviesView from './views/MoviesView.vue';
import TVShowView from './views/TVShowView.vue';
import GamesView from './views/GamesView.vue';
import BooksView from './views/BooksView.vue';
import APIExplorerView from './views/APIExplorerView.vue';
import SoftwareView from './views/SoftwareView.vue';
import AudiobooksView from './views/AudiobooksView.vue';
import DownloadView from './views/DownloadView.vue';
import NotFoundView from './views/NotFoundView.vue';
import LoginView from './views/LoginView.vue';
import ResetPasswordView from './views/ResetPasswordView.vue';
import AdminView from './views/AdminView.vue';
import HomeView from './views/HomeView.vue';

// Define your routes
const routes = [
	{ path: '/', component: HomeView },
	{ path: '/movies', component: MoviesView },
	{ path: '/shows', component: TVShowView },
	{ path: '/games', component: GamesView },
	{ path: '/software', component: SoftwareView },
	{ path: '/books', component: BooksView },
	{ path: '/audiobooks', component: AudiobooksView },
	{ path: '/downloads', component: DownloadView },
	{ path: '/apiExplorer', component: APIExplorerView },
	{ path: '/admin', component: AdminView },
	{ path: '/login', component: LoginView },
	{ path: '/resetPassword', component: ResetPasswordView },
	{ path: '/:catchAll(.*)', component: NotFoundView }, // 404 Not Found
];

// Object to store scroll positions for each route
const scrollPositions = {};

// Create the router instance
const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// Save the scroll position of the current route before navigating away
		if (from.fullPath) {
			scrollPositions[from.fullPath] = {
				left: window.scrollX,
				top: window.scrollY,
			};
		}

		// If there's a saved position (e.g., when using browser's back/forward buttons), use it
		if (savedPosition) {
			return savedPosition;
		}

		// If the target route has a saved scroll position, restore it
		if (scrollPositions[to.fullPath]) {
			return scrollPositions[to.fullPath];
		}

		// Otherwise, scroll to the top of the page
		return { top: 0 };
	},
});

export default router;
