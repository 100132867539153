<template>
	<tr :class="{ 'odd-row': isOdd, 'even-row': !isOdd }" class="clickable" @click="toggleDetails">
		<td class="icon" style="width: 0%">
			<v-avatar size="16" class="mr-2">
				<img :src="getIconPath(item.website)" alt="website icon" width="16" height="16" />
			</v-avatar>
		</td>
		<td class="default-padding" style="width: 50%; word-break: break-word"
			:style="{ color: isBadQuality(item) ? 'gray' : '' }">
			<div>
				<div class="icon-within-text ml-1 mr-2">
					<v-avatar size="16">
						<img :src="getIconPath(item.website)" alt="website icon" width="16" height="16" />
					</v-avatar>
				</div>
				<span v-if="isWithinLastThreeDays(item.date)" class="new-icon-float-right"
					:style="{ color: isBadQuality(item) ? '#A52A2A' : 'red' }">new!</span>
				{{ item.name }}
				<span v-if="isWithinLastThreeDays(item.date)" class="new-icon"
					:style="{ color: isBadQuality(item) ? '#A52A2A' : 'red' }">new!</span>
			</div>
		</td>
		<td class="default-padding seeder-column" style="width: 0%">{{ item.seeds }}</td>
		<td class="default-padding leecher-column" style="width: 0%">{{ item.leeches }}</td>
		<td class="default-padding date-column" style="width: 0%">{{ item.date }}</td>
		<td class="default-padding size-column" style="width: 8%">{{ item.size }}</td>
		<td class="default-padding uploader-column" style="width: 4%">{{ item.uploader }}</td>
		<td class="no-padding" style="width: 0%">
			<v-btn @click.stop="copyMagnet(item.magnetLink)" color="red" small class="fixed-width-btn">
				<v-progress-circular v-if="loading" indeterminate color="white"></v-progress-circular>
				<v-icon v-else-if="success">mdi-check</v-icon>
				<v-icon v-else-if="failure">mdi-close</v-icon>
				<span v-else>Copy</span>
			</v-btn>
		</td>
	</tr>
	<tr v-if="expanded" :class="{ 'odd-row': isOdd, 'even-row': !isOdd }" class="">
		<td colspan="8" style="padding: 0px;">
			<ResultComponentRowDetails :item="this.item" :detailsUrl="detailsUrl" />
		</td>
	</tr>
</template>

<script>
import ResultComponentRowDetails from "./ResultComponentRowDetails.vue";
import PirateBayIcon from "@/assets/piratebay.png";
import LeetxIcon from "@/assets/1337x.png";
import BitsearchIcon from "@/assets/bitsearch.png";
import backendService from "@/services/backendService";
import magnetService from "@/services/magnetService";

export default {
	name: "ResultComponentRow",
	components: {
		ResultComponentRowDetails,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		searchUrl: {
			type: String,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		magnetUrl: {
			type: String,
			required: true
		},
		isOdd: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			expanded: false,
			loading: false,
			success: false,
			failure: false,
		};
	},
	methods: {
		toggleDetails() {
			this.expanded = !this.expanded;
		},
		async copyMagnet(text) {
			const httpRegex = /^https?:\/\//i;
			const magnetRegex = /^magnet:\?/i;

			const copyToClipboard = (text) => {
				magnetService.addMagnet(text);

				try {
					navigator.clipboard.writeText(text).then(() => {
						this.success = true;
						setTimeout(() => {
							this.success = false;
						}, 2000);
					}).catch(err => {
						console.error("Failed to copy text: ", err);
						this.failure = true;
						setTimeout(() => {
							this.failure = false;
						}, 2000);
					});
				}
				catch (err) {
					console.error("Failed to copy text: ", err);
					this.failure = true;
					setTimeout(() => {
						this.failure = false;
					}, 2000);
				}
			};

			if (httpRegex.test(text)) {
				try {
					this.loading = true;
					const magnet = await backendService.get(`${this.magnetUrl}?url=${encodeURIComponent(text)}`);
					copyToClipboard(magnet);
				} catch (error) {
					console.error("Error fetching magnet URL: ", error);
					this.failure = true;
					setTimeout(() => {
						this.failure = false;
					}, 2000);
				} finally {
					this.loading = false;
				}
			} else if (magnetRegex.test(text)) {
				copyToClipboard(text);
			} else {
				console.warn("The provided text is neither a HTTP/HTTPS URL nor a magnet URL");
				this.failure = true;
				setTimeout(() => {
					this.failure = false;
				}, 2000);
			}
		},
		isWithinLastThreeDays(dateString) {
			const [day, month] = dateString.split('/').map(Number);
			const today = new Date();
			const currentYear = today.getFullYear();
			const inputDate = new Date(currentYear, month - 1, day);
			const timeDifference = today - inputDate;
			const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
			return dayDifference >= 0 && dayDifference <= 3;
		},
		getIconPath(website) {
			if (website === "1337x") {
				return LeetxIcon;
			} else if (website === "piratebay") {
				return PirateBayIcon;
			} else if (website === "bitsearch") {
				return BitsearchIcon;
			}
			return "";
		},
		badQualityTags() {
			return [
				"CAM", "TSRIP", "HDTS", "HD-CAM", "WEBCAM", "HDCAM", "HDCAM-RIP", "TS",
				"TELESYNC", "PDVD", "SD", "480P", "360P", "720P", "LD", "LOWDEF",
				"SCREENER", "SCR", "R5", "TC", "TELECINE", "DVDSCR", "WORKPRINT", "HINDI"
			];
		},
		tokenizeTitle(title) {
			return title.split(/[.\-_\s]/);
		},
		containsBadQualityTag(tokens) {
			const badTags = this.badQualityTags();
			return tokens.some(token => badTags.includes(token.toUpperCase()));
		},
		isBadQuality(item) {
			const tokens = this.tokenizeTitle(item.name);
			return this.containsBadQualityTag(tokens);
		},
	},
};
</script>

<style scoped>
.clickable:hover {
	cursor: pointer;
}

.icon {
	padding: 0px 8px 0px 8px !important;
}

.icon-within-text {
	padding: 0px !important;
	display: none;
	float: left;
}

.default-padding {
	padding: 0px 48px 0px 0px !important;
}

td {
	border-bottom: none !important;
}

.no-padding {
	padding: 0px !important;
}

.new-icon {
	color: red;
	font-weight: bold;
	margin-left: 8px;
	text-wrap: nowrap;
}

.new-icon-float-right {
	color: red;
	font-weight: bold;
	margin-left: 8px;
	text-wrap: nowrap;
	float: right;
	visibility: hidden;
}

.odd-row {
	background-color: #2f2f2f;
}

.even-row {
	background-color: #1f1f1f;
}

.fixed-width-btn {
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1300px) {
	.uploader-column {
		display: none;
	}

	.default-padding {
		padding: 0px 16px 0px 0px !important;
	}
}

@media (max-width: 900px) {
	.date-column {
		display: none;
	}

	.default-padding {
		padding: 0px 8px 0px 0px !important;
	}

	.new-icon {
		visibility: hidden;
	}

	.new-icon-float-right {
		visibility: visible;
	}
}

@media (max-width: 600px) {
	.icon {
		display: none;
	}

	.icon-within-text {
		display: block;
	}

	.leecher-column {
		display: none;
	}

	.seeder-column {
		display: none;
	}
}
</style>
